import React, { Component, Suspense } from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Container } from "reactstrap"
import axios from "axios"
import update from 'immutability-helper'
import Cookies from 'universal-cookie'
import { Helmet } from "react-helmet"
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import i18n from "i18next"

import ReactGA from 'react-ga'



import Utils from "./classes/utils"
import CartClass from "./classes/cart"


//Compenents
const ScrollToTop = React.lazy(() => import('./components/scroll-to-top'));
const ErrorDiv = React.lazy(() => import('./components/error'));
const NahibuFooter = React.lazy(() => import('./components/footer'));
const NahibuNavbar = React.lazy(() => import('./components/navbar'));
const RouteChangeTracker = React.lazy(() => import('./components/route-change-tracker'));
const CookieConsent = React.lazy(() => import('./components/cookie-consent'));
const EventBanner = React.lazy(() => import('./components/event-banner'));

//Routes
const Pudp = React.lazy(() => import('./routes/pudp'));
const Cgv = React.lazy(() => import('./routes/cgv'));
const CookiePolicy = React.lazy(() => import('./routes/cookie-policy'));
const Affilies = React.lazy(() => import('./routes/affilies'));
const Orders = React.lazy(() => import('./routes/orders'));
const Cart = React.lazy(() => import('./routes/cart'));
const CgvDistributeurs = React.lazy(() => import('./routes/cgv-distributeurs'));
const Order = React.lazy(() => import('./routes/order'));
const Consent = React.lazy(() => import('./routes/consent'));
const LegalNotice = React.lazy(() => import('./routes/legal-notice'));
const ExpertiseNahibu = React.lazy(() => import('./routes/expertise-nahibu'));
const Consulting = React.lazy(() => import('./routes/consulting'));
const Home = React.lazy(() => import('./routes/home'));
const AffiliatesAccount = React.lazy(() => import('./routes/affiliates-account'));
const Login = React.lazy(() => import('./routes/login'));
const Contact = React.lazy(() => import('./routes/contact'));
const PasswordReset = React.lazy(() => import('./routes/password-reset'));
const Error404 = React.lazy(() => import('./routes/404'));
const Account = React.lazy(() => import('./routes/account'));
const KitDetails = React.lazy(() => import('./routes/kit-details'));
const ProductsPage = React.lazy(() => import('./routes/product-page'));
const OrderValidation = React.lazy(() => import('./routes/order-validation'));




class Routes extends Component {

  constructor(props) {
    super(props)
    const cookies = new Cookies()
    const userCookie = cookies.get("user")
    this.state = {
      error: false,
      message: null,
      redirect: null,
      cart: {
        items: [],
        coupons: [],
        shippingAndTaxes: {},
        waiting: true,
      },
      config: {
        apiBaseUrl: window.config ? window.config.wordpressUrl : "https://wp.dev.nahibu.me",
        wooApiUrl: window.config ? window.config.wooApiUrl : "http://dev.local:5000",
        mybioteUrl: window.config ? window.config.mybioteUrl : "http://dev.local:8000",
        tokenAffiliatesViewer: window.config ? window.config.tokenAffiliatesViewer : "Token 1eeb189d1a1715318e9d4a6a4d98120e3ac5560e",
        cookieDomain: window.config ? window.config.cookieDomain : "dev.local",
        headers: userCookie ? {"Authorization": "Bearer " + userCookie.token} : {},
        products: window.config.products,
        PRODUCT_SKU: window.config.PRODUCT_SKU
      },
      store: [],
      shippingAndTaxes: {},
      waiting: true,
      auth: {
        user: {},
        logged: false,
        waiting: true,
      },
      cookies: {
        displayCookieBanner: false,
        displayCookieModal: false,
        necessary: true,
        performance: false,
        targetting: false
      },
      displayBanner: false,
    }
    this.utils = new Utils()
    this.toogleBanner = this.toogleBanner.bind(this)


    if (window.config.discountEvent) {
      const startDate = new Date(window.config.discountEvent.startDate)
      const endDate = new Date(window.config.discountEvent.endDate)
      const labelDesktop = window.config.discountEvent.labelDesktop
      const labelMobile = window.config.discountEvent.labelMobile

      this.state.config.discountEvent = {
        startDate,
        endDate,
        labelDesktop,
        labelMobile
      }
    }
  }

  displayCovidBanner() {
    const cookies = new Cookies()
    const bannerCovidCookie = cookies.get("banner-covid")

    const validCookieBanner = bannerCovidCookie ? (bannerCovidCookie.display ? true : false) : true

    if (validCookieBanner) {
      this.setState({
        displayBanner: true,
      })
    }
  }

  toogleBanner (event) {

    // store value in cookie
    const cookies = new Cookies()
    cookies.set('banner-covid', {
      display: !this.state.displayBanner
    }, { path: "/" })

    this.setState({
      displayBanner: !this.state.displayBanner
    })
    event.preventDefault()
  }

  removeUserFromSession () {
    const cookies = new Cookies()
    cookies.remove("user")
  }

  checkToken(callback) {
    let requestUrl = this.state.config.apiBaseUrl + "/wp-json/jwt-auth/v1/token/validate"
    let message
    axios.post(requestUrl, {}, {headers: this.state.config.headers}).then(response => {
      this.setState({
        auth: update(this.state.auth, {
          waiting: {$set: false},
        })
      }, () => typeof callback == "function" && callback())
    }).catch(error => {
      if (error.response) {
        // Redirect login if token is invalid
       if (error.response.status === 403) {
        this.removeUserFromSession()
        this.setState({
          redirect: "/login/",
          cart: {
            items: [],
            coupons: [],
            waiting: false,
            shippingAndTaxes: {},
          },
          auth: {
            user: {},
            logged: false,
            waiting: false,
          }})
          return
       }
      } else if (error.request) {
        console.error("Request error", error.request)
        message = "Erreur réseau, veuillez réessayer plus tard"
      } else {
        console.error("Unknown error", error.request)
      }
      this.setState({
        error: true,
        message: message
      })
    })
  }

  countCartItems() {
    let count = 0
    Object.keys(this.state.cart.items).forEach(key => {
      count += this.state.cart.items[key]["quantity"]
    })
    return count
  }

  getCookieCart (callback=null) {
    const cart = new CartClass(this.state.cart, this.props.store)
    cart.getFromCookie()
    this.setState({
      cart: cart.toDict(),
    }, () => typeof callback == "function" && callback())
  }

  getStore (callback=null) {
    const requestUrl = window.config.productsDB?.useEmbeddedProductsDB ? window.config.productsDB.embeddedProductsDBPath  : this.state.config.wooApiUrl + "/get-store"

    return axios.get(requestUrl).then(response => {

      // default shipping and taxes
      let snt = {}

      if (response.data.shipping_and_taxes.countries) {
        response.data.shipping_and_taxes.countries.forEach(country => {
          if (country.iso === "FR") {
            snt = country
          }
        })
      }

      this.setState({
        waiting: false,
        store: response.data.products,
        shippingAndTaxes: response.data.shipping_and_taxes,
        cart: update(this.state.cart, {
          shippingAndTaxes: {$set: snt},
        })
      }, () => {
        typeof callback == "function" && callback()
      })
    }).catch(error => {
      throw error
    })
  }

  getCookiePref() {
    const cookies = new Cookies()
    const cookieConsent = cookies.get("cookie-consent")
    if (cookieConsent) {
      const performance = cookieConsent.hasOwnProperty('performance') ? cookieConsent.performance : false
      const targetting = cookieConsent.hasOwnProperty('targetting') ? cookieConsent.targetting : false
      this.setState({
        cookies: {
          displayCookieBanner: false,
          displayCookieModal: false,
          necessary: true,
          performance: performance,
          targetting: targetting,
        }
      })
      // Enable GA if performance is set to true
      if (performance) {
        this.enableGoogleAnalytics()
      }
      // Enable FB and Sendinblue if targetting is true
      if (targetting) {
        this.enableTracking(["facebook", "sendinblue", "gtag-script", "gtag-inline"])
      }
    } else {
      this.setState({
        cookies: {
          displayCookieBanner: true,
          displayCookieModal: false,
          necessary: true,
          performance: false,
          targetting: false,
        }
      })
    }
  }

  removeCookies(performance, targetting) {
    const performanceCookies = ["_ga", "_gid", "_gat"]
    const targettingCookies = ["sib_cuid"]
    const cookies = new Cookies()
    if (performance) {
      performanceCookies.forEach(cookieName => {
        cookies.remove(cookieName)
      })
    }
    if (targetting) {
      targettingCookies.forEach(cookieName => {
        cookies.remove(cookieName)
      })
    }
  }

  enableGoogleAnalytics() {
    const googleAnalyticsId = window.config ? window.config.googleAnalyticsId : null
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId, {
        debug: false,
      })
      ReactGA.plugin.require('ec')
      const url = window.location.pathname + window.location.search
      ReactGA.set({ page: url })
      ReactGA.pageview(url)
    }
  }

  enableTracking(trackingList) {
    // Inspired from Orejime
    // https://github.com/empreinte-digitale/orejime/blob/dcc76ef563b9e58630f493e55edd734c50bb62b6/src/consent-manager.js#L160

    trackingList.forEach(tracker => {
      const elements = document.querySelectorAll("script[data-name=" + tracker + "]")
      elements.forEach(element => {

        const parent = element.parentElement
        const {dataset} = element
        const {type} = dataset

        //if no consent was given we disable this tracker
        //we remove and add it again to trigger a re-execution

        if (element.tagName === 'SCRIPT'){
            // we create a new script instead of updating the node in
            // place, as the script won't start correctly otherwise
            const newElement = document.createElement('script')
            for(const key of Object.keys(dataset)){
                newElement.dataset[key] = dataset[key]
            }
            newElement.type = 'opt-in'
            newElement.innerText = element.innerText
            newElement.text = element.text
            newElement.class = element.class
            newElement.style.cssText = element.style
            newElement.id = element.id
            newElement.name = element.name
            newElement.defer = element.defer
            newElement.async = element.async

            newElement.type = type
            if (dataset.src !== undefined)
                newElement.src = dataset.src

            //we remove the original element and insert a new one
            parent.insertBefore(newElement, element)
            parent.removeChild(element)
          }
      })
    })
  }

  async componentDidMount() {

    this.displayCovidBanner()

    this.getCookiePref()

    // First, get store
    await this.getStore(() => {
      // Check if a user is stored in cookie
      const cookies = new Cookies()
      const cookieUser = cookies.get("user")

      const validCookieUser = cookieUser ? (cookieUser.token ? true : false) : false

      if (validCookieUser) {
        // First, log user
        const user = {
          id: cookieUser.id,
          username: cookieUser.username,
          displayName: cookieUser.displayName,
          fname: cookieUser.fname,
          lname: cookieUser.lname,
          email: cookieUser.email,
          token: cookieUser.token
        }
        this.setState({
          auth: update(this.state.auth, {
            user: {$set: user},
            logged: {$set: true}
          })
        }, () => {
          // then, check token and get cart
          this.checkToken(() => {
            this.getCookieCart()
          })
        })
      } else {
        // gest user
        this.setState({
          auth: update(this.state.auth, {
            waiting: {$set: false}
          }),
        })

        // set cart from cookie
        this.getCookieCart(() => {
          this.setState({
            cart: update(this.state.cart, {
              waiting: {$set: false}
            })
          })
        })
      }

    }).catch(error => {
      this.setState({
        error: true,
      })
      return
    })

  }


  render () {

    const helmet = (
      <Helmet>
          <meta charSet="utf-8" />
      </Helmet>
    )

    if(window.location.href.includes("nahibu.es") && !window.location.pathname.includes("/es/")){
      const oldPath = window.location.pathname.replace("en/", "").replace("fr/", "").replace("es/", "")
      const newPath = "/es" + oldPath

      this.setState({
        redirect: newPath
      })

      i18n.changeLanguage("es")
    }

    let redirect = this.state.redirect ? <Redirect to={this.state.redirect} /> : null
    const t = this.props.t
    const language = i18n.language ? ( i18n.language.startsWith("fr") ? "fr" : i18n.language.startsWith("en") ? "en" : 'es' ) : undefined
    const langSubPath = language === "en" ? "/en" : language === "es" ? "/es" : ""

    return (



      <Router>
        <Suspense fallback={<div></div>}>
        <RouteChangeTracker />
        </Suspense>
        {helmet}
        {redirect}
        <Suspense fallback={<div></div>}>
        <ScrollToTop />
        </Suspense>
        <Suspense fallback={<div></div>}>
        <NahibuNavbar config={this.state.config} auth={this.state.auth} setStateRoot={(p, cb) => this.setState(p, cb)} waitingCart={this.state.cart.waiting} cartNumber={this.countCartItems()} />
        </Suspense>
        { this.state.config.discountEvent &&
          <Suspense fallback={<div></div>}>
            <Link to={langSubPath + "/" + t("path|details-produit") + "/?1"}>
              <EventBanner
                cssclass="desktop-only bg-dark-blue"
                position={80}
                startDate={ this.state.config.discountEvent.startDate }
                endDate={ this.state.config.discountEvent.endDate }
                content={ this.state.config.discountEvent.labelDesktop[language] }>
              </EventBanner>
              <EventBanner
                cssclass="mobile-only bg-dark-blue "
                position=""
                content={ this.state.config.discountEvent.labelMobile[language] }>
              </EventBanner>
            </Link>
          </Suspense>
        }
        <Container style={{height: "80px"}}></Container>
        <Switch>
          <Route path={"/:locale(es|en|fr)?/"} exact component={() => (<Suspense fallback={<div></div>}><Home  auth={this.state.auth} config={this.state.config} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/expertise-nahibu/", "/:locale(es|en|fr)?/" + t("path|expertise-nahibu") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><ExpertiseNahibu /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/consultation/", "/:locale(es|en|fr)?/" + t("path|consultation") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Consulting config={this.state.config} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/affilies/", "/:locale(es|en|fr)?/" + t("path|affilies") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Affilies config={this.state.config} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/orders/", "/:locale(es|en|fr)?/" + t("path|orders") + "/"]} exact component={(props) => (<Suspense fallback={<div></div>}><Orders {...props} config={this.state.config} auth={this.state.auth} cart={this.state.cart} setStateRoot={(p, cb) => this.setState(p, cb)} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/login/", "/:locale(es|en|fr)?/" + t("path|login") + "/"]} exact component={(props) => (<Suspense fallback={<div></div>}><Login {...props} getCookieCart={p => this.getCookieCart(p)} config={this.state.config} auth={this.state.auth} cart={this.state.cart} setStateRoot={(p, cb) => this.setState(p, cb)} /></Suspense>)}  />
          <Route path={["/:locale(es|en|fr?)/panier/", "/:locale(es|en|fr)?/" + t("path|panier") + "/"]} render={(routeProps) => <Suspense fallback={<div></div>}><Cart getCookieCart={p => this.getCookieCart(p)} waitingStore={this.state.waiting} {...routeProps} auth={this.state.auth} cart={this.state.cart} store={this.state.store} config={this.state.config} setStateRoot={(p, cb) => this.setState(p, cb)} /></Suspense>} />
          <Route path={["/:locale(es|en|fr?)/order/", "/:locale(es|en|fr)?/" + t("path|order") + "/"]} render={(routeProps) => <Suspense fallback={<div></div>}><Order {...routeProps} auth={this.state.auth} waitingStore={this.state.waiting} cart={this.state.cart} store={this.state.store} shippingAndTaxes={this.state.shippingAndTaxes} config={this.state.config} setStateRoot={(p, cb) => this.setState(p, cb)} getCookieCart={p => this.getCookieCart(p)} /></Suspense>} />
          <Route path={["/:locale(es|en|fr?)/contact/", "/:locale(es|en|fr)?/" + t("path|contact") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Contact config={this.state.config} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/mentions-legales-distributeurs/", "/:locale(es|en|fr)?/" + t("path|mentions-legales-distributeurs") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><CgvDistributeurs /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/mentions-legales/", "/:locale(es|en|fr)?/" + t("path|mentions-legales") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><LegalNotice /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/cgv/", "/:locale(es|en|fr)?/" + t("path|cgv") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Cgv /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/politique-cookie/", "/:locale(es|en|fr)?/" + t("path|politique-cookie") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><CookiePolicy /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/pudp/", "/:locale(es|en|fr)?/" + t("path|pudp") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Pudp /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/consentement/", "/:locale(es|en|fr)?/" + t("path|consentement") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Consent /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/account/", "/:locale(es|en|fr)?/" + t("path|account") + "/"]} exact component={() => (<Suspense fallback={<div></div>}><Account setStateRoot={(p, cb) => this.setState(p, cb)} config={this.state.config} auth={this.state.auth} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/password_reset/", "/:locale(es|en|fr)?/" + t("path|password_reset") + "/"]} exact component={(props) => (<Suspense fallback={<div></div>}><PasswordReset setStateRoot={(p, cb) => this.setState(p, cb)} config={this.state.config} auth={this.state.auth} {...props} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/compte-affilie/", "/:locale(es|en|fr)?/" + t("path|compte-affilie") + "/"]} exact component={(props) => (<Suspense fallback={<div></div>}><AffiliatesAccount {...props} getCookieCart={p => this.getCookieCart(p)} setStateRoot={(p, cb) => this.setState(p, cb)} config={this.state.config} auth={this.state.auth} /></Suspense>)} />
          <Route path={["/:locale(es|en|fr?)/details-produit/", "/:locale(es|en|fr)?/" + t("path|details-produit") + "/"]} render={(routeProps) => <Suspense fallback={<div></div>}><KitDetails {...routeProps} waitingStore={this.state.waiting} config={this.state.config} auth={this.state.auth} product={this.state.product} setStateRoot={(p, cb) => this.setState(p, cb)}/></Suspense>} />
          <Route path={["/:locale(es|en|fr?)/produits/", "/:locale(es|en|fr)?/" + t("path|produits") + "/"]} render={(routeProps) => <Suspense fallback={<div></div>}><ProductsPage {...routeProps} waitingStore={this.state.waiting} config={this.state.config} auth={this.state.auth} setStateRoot={(p, cb) => this.setState(p, cb)}/></Suspense>} />
          <Route path={["/:locale(es|en|fr?)/confirmation-commande/", "/:locale(es|en|fr)?/" + t("path|confirmation-commande") + "/"]} render={(routeProps) => <Suspense fallback={<div></div>}><OrderValidation {...routeProps} config={this.state.config} auth={this.state.auth} cart={this.state.cart} setStateRoot={(p, cb) => this.setState(p, cb)}/></Suspense>} />


          <Route component={() => <Suspense fallback={<div></div>}><Error404/></Suspense>} />
        </Switch>
        <Suspense fallback={<div></div>}>
        <ErrorDiv type="bottom" error={this.state.error} message={this.state.message} />
        </Suspense>



        {/* Cookie consent */}
        <Suspense fallback={<div></div>}>
        <CookieConsent removeCookies={this.removeCookies} enableTracking={this.enableTracking} enableGoogleAnalytics={this.enableGoogleAnalytics} debug={window.config ? window.config.debugCookieConsent : false} cookies={this.state.cookies} setStateRoot={(p, cb) => this.setState(p, cb)} />
        </Suspense>

        {/* Footer */}
        <Suspense fallback={<div></div>}>
        <NahibuFooter config={this.state.config} />
        </Suspense>
      </Router>
    )
  }
}

export default withTranslation()(Routes)
